import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Aside from "../components/aside"
import { graphql, Link } from "gatsby"
import { useEffect } from "react"

export default function Cennik({ data }) {
  const posts = data.posts.edges.map(({ node }) => node.frontmatter)

  const seo = {
    title: "Cennik operacji plastycznych - Klinika Chirurgii Plastycznej | Szczecin",
    description: "Sprawdź cennik operacji i zabiegów plastycznych oferowanych w Centrum Chirurgii Plastycznej w Szczecinie. Dr Maciej Pastucha - oddaj się w ręce specjalisty!",
    url: "https://mpastucha.pl/cennik"
  }

  useEffect(() => {
    let interval = setInterval(() => {
      if (typeof window !== "undefined" && window.PricesPageInit && window.$) {
        clearInterval(interval);
        window.PricesPageInit();
      }
    }, 50)
  });

  return (
    <Layout headerClasses={'mainheader-subpage mainheader-white'} classes={'bg-creme'}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <header id="top" className="fake-placeholder" />
      <a href="tel:913112228" className="phonelink"><img src="/images/phone.svg" alt="Ikona telefon" /></a>
      <div className="fullwidth">
        <img src="/uploads/2015/09/prices-1920x730-1920x730-c-default.jpg" alt="chirurgia estetyczna" />
      </div>
      <div className="container-fluid sct">
        <div className="row pad-l pad-r">
          <Aside posts={posts}/>
          <section className="col-9md sct-prices">
            <h1>Cennik</h1>
            <table className="table table-striped table-striped-prices">
              <thead>
              <tr>
                <th>
                  <h4>Zabieg</h4>
                </th>
                <th className="txt-al-r">
                  <h4>Cena</h4>
                  <small>kliknij na zabieg, aby poznać szczegóły</small>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><Link to="/zabiegi/plastyka-powiek-gornych-i-dolnych"> Plastyka powiek górnych </Link></td>
                <td className="txt-al-r">7800 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/plastyka-powiek-gornych-i-dolnych"> Plastyka powiek dolnych </Link></td>
                <td className="txt-al-r">9300 zł</td>
              </tr>
              <tr>
                <td> Korekta zmarszczek twarzy kwasem hialuronowym Restylane 1 ml (1 amp.)</td>
                <td className="txt-al-r">1200 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/powiekszanie-ust/"> Powiększanie ust kwasem hialuronowym </Link></td>
                <td className="txt-al-r">1400 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/likwidacja-zmarszczek-mimicznych-twarzy-botox/"> Likwidacja zmarszczek mimicznych
                  botoxem (1 okolica) </Link></td>
                <td className="txt-al-r">600 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/korekcja-nosa-i-przegrody/"> Pełna korekta nosa w znieczuleniu ogólnym </Link>
                </td>
                <td className="txt-al-r">24 200 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/korekcja-nosa-i-przegrody/"> Korekta nosa części chrzęstnej w znieczuleniu
                  ogólnym </Link></td>
                <td className="txt-al-r">18 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/korekcja-odstajacych-malzowin-usznych/"> Korekcja odstających małżowin
                  usznych </Link></td>
                <td className="txt-al-r">9200 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/powiekszanie-piersi-okragle/"> Powiększanie piersi implantami okrągłymi Mentor </Link></td>
                <td className="txt-al-r">24 200 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/powiekszanie-piersi-okragle/"> Powiększanie piersi implantami okrągłymi Mentor
                  (implanty XTRA) </Link></td>
                <td className="txt-al-r">26 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/powiekszanie-piersi-anatomiczne/"> Powiększanie piersi implantami anatomicznymi
                  Mentor </Link></td>
                <td className="txt-al-r">26 500 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/podniesienie-z-powiekszeniem-piersi/"> Powiększanie piersi implantami okrągłymi z
                  równoczesnym podniesieniem piersi </Link></td>
                <td className="txt-al-r">29 200 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/podniesienie-z-powiekszeniem-piersi/"> Powiększanie piersi implantami okrągłymi z
                  równoczesnym podniesieniem piersi (implanty XTRA) </Link></td>
                <td className="txt-al-r">30 700 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/podniesienie-z-powiekszeniem-piersi/"> Powiększanie piersi implantami
                  anatomicznymi z równoczesnym podniesieniem piersi </Link></td>
                <td className="txt-al-r">32 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/podniesienie-opadnietych-piersi/"> Podniesienie opadniętych piersi
                  (mastopexia) </Link></td>
                <td className="txt-al-r">25 200 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/redukcja-przerostu-piersi/"> Redukcja przerostu piersi </Link></td>
                <td className="txt-al-r">26 500 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/ginekomastia/"> Ginekomastia </Link></td>
                <td className="txt-al-r">15 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/plastyka-powlok-brzucha/"> Plastyka pełna brzucha </Link></td>
                <td className="txt-al-r">27 500 zł</td>
              </tr>
              <tr>
                <td> Plastyka dolnej części brzucha (mini
                  plastyka) </td>
                <td className="txt-al-r">22 500 zł</td>
              </tr>
              <tr>
                <td> Plastyka dolnej części brzucha (mini
                  plastyka z odsysaniem) </td>
                <td className="txt-al-r">24 500 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/plastyka-powlok-brzucha-z-liposukcja/"> Plastyka brzucha z odsysaniem
                  (liposukcja) </Link></td>
                <td className="txt-al-r">29 500 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/liposukcja/"> Liposukcja </Link></td>
                <td className="txt-al-r">18 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/liposukcja/"> Liposukcja kolejnej partii ciała </Link></td>
                <td className="txt-al-r">4 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/lifting-ramion/">Lifting ramion </Link></td>
                <td className="txt-al-r">18 000 zł</td>
              </tr>
              <tr>
                <td><Link to="/zabiegi/lifting-ramion/">Lifting ramion </Link></td>
                <td className="txt-al-r">19 000 zł</td>
              </tr>
              <tr>
                <td>Lifting ud</td>
                <td className="txt-al-r">19 000 zł</td>
              </tr>
              <tr>
                <td>Pobyt jednodniowy (do godz 12.00)</td>
                <td className="txt-al-r">800 zł</td>
              </tr>
              </tbody>
            </table>
            <p>
              W zależności od stopnia skomplikowania zabiegu ceny mogą ulec zmianie
            </p>
            <p>
            Uwaga - cennik nie stanowi oferty w rozumieniu przepisów Kodeksu prawa cywilnego, a powyższe
              ceny są cenami orientacyjnymi i są zawsze uzgadniane indywidualnie pomiędzy lekarzem a
              pacjentem w trakcie konsultacji. W przypadku niestandardowych operacji – ceny są negocjowane.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query Pricelist {
        posts: allMarkdownRemark {
            edges {
                node {
                    html
                    frontmatter {
                        slug
                        title
                        category
                    }
                }
            }
        }
    }`
